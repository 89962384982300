import * as L from "leaflet";
import * as Papa from "papaparse";

let allMarkers = [];

$(() => {
  if ($(".map-wrapper").length) {
    initMap();
  }
});

const pinIcon = L.divIcon({
  className: "location-pin",
  html:
    '<svg viewBox="0 0 500 820" version="1.1" xmlns="http://www.w3.org/2000/svg" xml:space="preserve" style="fill-rule: evenodd; clip-rule: evenodd; stroke-linecap: round;"><g transform="matrix(19.5417,0,0,19.5417,-7889.1,-9807.44)"><path d="M416.544,503.612C409.971,503.612 404.5,509.303 404.5,515.478C404.5,518.256 406.064,521.786 407.194,524.224L416.5,542.096L425.762,524.224C426.892,521.786 428.5,518.433 428.5,515.478C428.5,509.303 423.117,503.612 416.544,503.612ZM416.544,510.767C419.128,510.784 421.223,512.889 421.223,515.477C421.223,518.065 419.128,520.14 416.544,520.156C413.96,520.139 411.865,518.066 411.865,515.477C411.865,512.889 413.96,510.784 416.544,510.767Z" stroke-width="1.1px" fill="currentColor" stroke="currentColor"/></g></svg>',
  iconAnchor: [8, 28],
});

function initMap() {
  const map = L.map("map", {
    scrollWheelZoom: false,
  }).setView([51.133481, 10.018384], 6);

  // Base map layer
  L.tileLayer("https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png", {
    attribution:
      'Map data © <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors',
  }).addTo(map);

  // Load and display GeoJSON data on the map
  fetch("/static/geojson/germanystates.geojson")
    .then(function (response) {
      return response.json();
    })
    .then(function (data) {
      // Create a GeoJSON layer with the data
      const geoJsonLayer = L.geoJSON(data, {
        style: {
          fillColor: "transparent", // Fill color of the state boundaries
          weight: 2, // Border width
          color: "#009374", // Border color
          fillOpacity: 0.4, // Opacity of the fill
        },
        onEachFeature: function (feature, layer) {
          // Attach a click event to zoom in on the state
          layer.on("click", function () {
            map.fitBounds(layer.getBounds());
          });
        },
      });

      // Add the GeoJSON layer to the map
      geoJsonLayer.addTo(map);
    });

  // Fetch and parse CSV data
  Papa.parse($(".map-wrapper").data("locations-url"), {
    download: true,
    header: true,
    skipEmptyLines: true,
    complete: function (results) {
      const locations = results.data;

      allMarkers = locations.map(function (location) {
        // Parse latitude and longitude, and validate them
        const lat = parseFloat(location.Location.split(";")[0]);
        const lon = parseFloat(location.Location.split(";")[1]);

        if (!isNaN(lat) && !isNaN(lon)) {
          const marker = L.marker([lat, lon], { icon: pinIcon });
          marker.locationData = location;
          return marker;
        } else {
          return null;
        }
      });

      // filter out null markers
      allMarkers = allMarkers.filter(function (marker) {
        return marker !== null;
      });

      // add markers to the map initially
      allMarkers.forEach(function (marker) {
        marker.addTo(map);
      });

      // Event listener for marker click
      allMarkers.forEach(function (marker) {
        marker.on("click", function () {
          showMarkerInfo(marker.locationData);
          for (const marker of allMarkers)
            marker._icon && marker._icon.classList.remove("active");
          marker._icon.classList.add("active");
        });
      });

      const form = document.getElementById("mapFilterForm");
      // autosubmit
      form.querySelectorAll("input").forEach((el) => {
        el.addEventListener("change", () => {
          applyFilters(map);
        });
      });

      //call a function to apply filters when form is submitted
      form.addEventListener("submit", function (event) {
        event.preventDefault();
        applyFilters(map);
      });

      // init min max
      document.getElementById("textbox1").value = Math.min(
        ...allMarkers.map((loc) => parseFloat(loc.locationData["Capacity (kWp)"]))
      );
      document.getElementById("textbox2").value = Math.max(
        ...allMarkers.map((loc) => parseFloat(loc.locationData["Capacity (kWp)"]))
      );
      document.getElementById("textbox3").value = Math.min(
        ...allMarkers.map((loc) => parseFloat(loc.locationData["Land (ha)"]))
      );
      document.getElementById("textbox4").value = Math.max(
        ...allMarkers.map((loc) => parseFloat(loc.locationData["Land (ha)"]))
      );
    },
  });
}

function applyFilters(map) {
  // Get the filter inputs
  const minCapacity = parseFloat(document.getElementById("textbox1").value);
  const maxCapacity = parseFloat(document.getElementById("textbox2").value);
  const minLand = parseFloat(document.getElementById("textbox3").value);
  const maxLand = parseFloat(document.getElementById("textbox4").value);
  const form = document.getElementById("mapFilterForm");
  const activeCategories = Array.from(form.querySelectorAll("[name=category]")).map(
    (checkbox) => {
      if (checkbox.checked) return checkbox.value;
    }
  );
  console.log(activeCategories);

  // Iterate through all markers and apply filtering criteria
  allMarkers.forEach(function (marker) {
    const capacity = parseFloat(marker.locationData["Capacity (kWp)"]);
    const land = parseFloat(marker.locationData["Land (ha)"]);
    const category = marker.locationData["Category"];
    let isValid = true;
    if (minCapacity && capacity < minCapacity) isValid = false;
    if (maxCapacity && capacity > maxCapacity) isValid = false;
    if (minLand && land < minLand) isValid = false;
    if (maxLand && land > maxLand) isValid = false;
    if (category && land > maxLand) isValid = false;
    if (!activeCategories.includes(category)) isValid = false;
    if (isValid) marker.addTo(map);
    else map.removeLayer(marker);
  });
}

// Function to show marker information in the sidebar as a table
function showMarkerInfo(data) {
  const sidebar = document.getElementById("info-panel");
  // Clear the existing content of the sidebar
  sidebar.innerHTML = "";

  if ("Logo" in data && data["Logo"]) {
    const base64 = data["Logo"];
    const div = document.createElement("div");
    div.classList.add("logo");
    const img = new Image();
    img.src = "data:image/png;base64," + base64;
    div.appendChild(img);
    sidebar.appendChild(div);
  }

  // Create a table and a tbody element
  const table = document.createElement("table");
  const tbody = document.createElement("tbody");

  // Iterate through the data and create table rows with parameter names and values
  for (const key in data) {
    if (key && !key.startsWith("_") && !["Logo", "Picture"].includes(key)) {
      const value = data[key];

      // Create a table row
      const row = document.createElement("tr");

      // Create a cell for the parameter name (left column)
      const paramNameCell = document.createElement("th");
      paramNameCell.textContent = key;

      // Create a cell for the parameter value (right column)
      const paramValueCell = document.createElement("td");
      paramValueCell.textContent = value;

      // Append cells to the row
      row.appendChild(paramNameCell);
      row.appendChild(paramValueCell);

      // Append the row to the tbody
      tbody.appendChild(row);
    }
  }

  // Append the table to the sidebar
  table.appendChild(tbody);
  sidebar.appendChild(table);

  if ("Picture" in data && data["Picture"]) {
    const base64 = data["Picture"];
    const div = document.createElement("div");
    div.classList.add("picture");
    const img = new Image();
    img.src = "data:image/png;base64," + base64;
    div.appendChild(img);
    const caption = document.createElement("caption");
    caption.innerHTML = "&copy; Fraunhofer ISE";
    div.appendChild(caption);
    sidebar.appendChild(div);
  }
}
