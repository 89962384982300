$(document).ready(function() {
  $(".carousel").slick({
    dots: false,
    infinite: true,
    speed: 300,
    slidesToShow: 1,
    adaptiveHeight: true,
    autoplay: true,
    autoplaySpeed: 5000,
    speed: 700,
    arrows: false,
  });
});


