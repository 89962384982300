import $ from "jquery";
window.$ = window.jQuery = $;

import "bootstrap";

import "./js/fouc.js";
import "slick-carousel";
import "slick-carousel/slick/slick.css";
import "./js/slick.js";
import "./js/map.js";

// Images

// Favicons

// Fonts
import "font-awesome/css/font-awesome.css";

// Styles
import "leaflet/dist/leaflet.css";
import "./scss/style.scss";

$(function () {
  if ($(".logo-container .sticky-logo").length) {
    let stickyScrollHeight = $("body").height() - $(".logo-container").height();
    $(".logo-container .logo").height(stickyScrollHeight);
  }

  let $sliderNavs = $(".slider-nav a[data-slide-goto]");
  let $slick = $(".carousel");
  $sliderNavs.each((index, item) => {
    $(item).click(function (e) {
      e.preventDefault();
      let slideIndex = parseInt($(this).attr("data-slide-goto"));
      let curSlideIndex = $slick.slick("slickCurrentSlide");
      if (curSlideIndex !== slideIndex) {
        $slick.slick("slickGoTo", slideIndex);
      }
    });
  });
  $slick.on("beforeChange", function (event, slick, curIndex, nextIndex) {
    $sliderNavs.removeClass("active");
    $sliderNavs.filter('[data-slide-goto="' + nextIndex + '"]').addClass("active");
  });

  // mobile main menu: expand subnavs
  $(".main-menu .nav-item.dropdown > .nav-link").click(function (e) {
    if ($("#navbarNavAltMarkup").hasClass("show")) {
      e.preventDefault();
      $(this).parent().children(".dropdown-menu").toggleClass("show");
    }
  });
});

if (module.hot) {
  module.hot.accept();
}
